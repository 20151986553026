import * as React from "react"
import './styles/Global.css'
import styles from './styles/index.styles.js'
import ecommerceImage from './images/example1.webp'
import weatherAppImage from './images/weatherapp.webp'
import jsts from './images/jsts.png'
import mernLogo from './images/mern-logo.png'
import graphqlIcon from './images/graphqlicon.png'
import apolloLogo from './images/apollologo.png'

const { useEffect, useRef } = React
const gridItemClasses = 'justify-center rounded-md bg-gray-100 grow'

const App = () => {

  const better = useRef()
  const grand = useRef()
  const modularity = useRef()
  const portfolio = useRef()
  const id = useRef()

  useEffect(() => {
    async function headerAnimation() {
      const typewriterAnimationElements = [...document.querySelectorAll('.typewriter')]
      typewriterAnimationElements.concat([portfolio.current,id.current])
      .forEach(el => {
        el.style.transition = 'all 1s ease-out'
      })
      better.current.classList.add('nodelay')
      await new Promise(res => {
        setTimeout(() => {
          better.current.classList.remove("typewriter")
          grand.current.style.opacity = 1
          res()
        },1000)
      })
      grand.current.classList.add('delay-1')
      await new Promise(res => {
        setTimeout(() => {
          grand.current.classList.remove("typewriter")
          modularity.current.style.opacity = 1
          res()
        },1000)
      })

      modularity.current.classList.add('delay-2')
      await new Promise(res => {
        setTimeout(() => {
          modularity.current.classList.remove('typewriter')
          id.current.style.opacity = 1
          res()
        },1000)
      })

      await new Promise(res => {
        setTimeout(() => {
          portfolio.current.style.opacity = 1
          res()
        },1000)
      })

    }
    headerAnimation()
  },[], () => {

    if (window.localStorage)
      window.localStorage.setItem("introAnimation", "true")
  })

  return (
    <main style={styles.page}>

      <div style={styles.strip}>
        <div style={{...styles.container,...styles.headerContainer}} className="container mx-auto">
          <h1 ref={better} style={styles.heading} className="typewriter">
            Better
          </h1>
          <h1 ref={grand} style={styles.heading} className="typewriter">
            Grand
          </h1>
          <h1 ref={modularity} style={styles.heading} className="typewriter">
            Modularity
          </h1>
        </div>
      </div>

      <div ref={id} style={{...styles.strip, ...styles.id}}>
          <p style={styles.paragraph}><h3 style={styles.heading2}>Keith Ricker</h3></p>
          <p style={{...styles.paragraph, ...styles.heading3, ...styles.portfolioP}}>
            Web Application Developer
            <br />
            Solutions Architect
            <br />
            KeithAlanR@outlook.com
          </p>

          <div style={{...styles.gridBlocks,...styles.container, ...styles.idGridBlocks}} className="grid grid-cols-1 md:grid-cols-4 gap-12">
            <div style={styles.idGridImgWrapper} className={gridItemClasses}>
              <img alt="" src={jsts} />
            </div>
            <div style={styles.idGridImgWrapper} className={gridItemClasses}>
              <img alt="" src={mernLogo} />
            </div>
            <div style={styles.idGridImgWrapper} className={gridItemClasses}>
              <img alt="" src={apolloLogo} />
            </div>
            <div style={styles.idGridImgWrapper} className={gridItemClasses}>
              <img alt="" src={graphqlIcon} />
            </div>
          </div>
      </div>
      <div ref={portfolio} style={{...styles.strip, ...styles.portfolio}}>
        <div style={{...styles.container, ...styles.portfolioContainer}} className="container mx-auto">
          <p style={styles.paragraph}>
            <h2 style={styles.heading2}>Portfolio / Code Samples</h2>
          </p>
          <div style={styles.gridBlocks} className="grid grid-cols-1 md:grid-cols-2 gap-6">
             <div style={{...styles.gridItem, paddingBottom:30,position:'relative'}} className={gridItemClasses}>
                <div className="gridImgWrapper"><img alt="" src={ecommerceImage} /></div>
                <div className="gridTextWrapper">
                  <h4>Ecommerce</h4>
                  E-commerce Boilerplate built with React
                  <ul>
                    <li>Server-side Rendered with a light framework (1 file of code)</li>
                    <li>Payment processing using Stripe</li>
                    <li>User Authentication using Firebase(enabled)</li>
                    <li>Redux State Management(Redux Toolkit)</li>
                    <li>Accessibility</li>
                    <li>GraphQL Enabled</li>
                    <li>Single Page App with Persistence(via redux persist)</li>
                    <li>High Performance Metrics.</li>
                  </ul>
                </div>
                <div style={{position:'absolute',bottom:'10',width:'100%',textAlign:'center',color:'#771c77'}}>
                  <a href="https://e-commerce-boilerplate.herokuapp.com/" target="_blank" rel="noreferrer">View the App</a>
                  &nbsp; &nbsp; &nbsp;
                  <a href="https://codesandbox.io/p/github/keithricker/e-commerce-template/main?file=%2FREADME.md" target="_blank" rel="noreferrer">View the Code</a>
                </div>
              </div>

              <div style={{...styles.gridItem, paddingBottom:30,position:'relative'}} className={gridItemClasses}>
                <div className="gridImgWrapper"><img alt="" src={weatherAppImage} /></div>
                <div className="gridTextWrapper">
                  <h4>API Solution</h4>
                  <ul>
                    <li>Full solution for consumption of data from APIs</li>
                    <li>Built with Typescript, using React</li>
                    <li>No wasted API calls</li>
                    <li>Single Page App with Persistence(via redux persist)</li>
                    <li>Automatically displays tailored data based user's location</li>
                    <li>Consumes 3 separate API endpoints, parsing data in a readable and consumable format</li>
                    <li>No bloat or unnecessary data used or stored on the client</li>
                  </ul>
                </div>
                <div style={{position:'absolute',bottom:'0',width:'100%',textAlign:'center', color:'#771c77'}}>
                   <a href="https://react-weather-app-green-eight.vercel.app/" target="_blank" rel="noreferrer">View the App</a> 
                   &nbsp; &nbsp; &nbsp;
                  <a href="https://codesandbox.io/s/github/keithricker/react-weather-app?file=/README.md" target="_blank" rel="noreferrer">View the Code</a>
                </div>
              </div>

          </div>
        </div>
      </div>

      <div style={{...styles.strip}}>
        <div style={styles.container} className="container mx-auto">

          <h2 style={styles.heading2}>Why BGM?</h2>
          <p>Because your app shouldn't have to depend on a thousand different frameworks and libraries. 
          Use just a few small modules consisting of a few files of readable code. Only what's needed.</p>
          
          <div style={{...styles.gridBlocks,...styles.container}} className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className={gridItemClasses}>
              <div className="gridTextWrapper">
                <h4>Turn This:</h4>
                <p><strong>Web Developer Wanted</strong></p>
                <p>Must know React, Angular, VueJS, NodeJS, CSS (Sass/Less)</p>
                <ul>
                  <li>Every CSS Framework</li>
                  <li>Literally every Node Framework</li>
                  <li>Every React library/framework including Next,Gatsby</li>
                  <li>Maybe a few Java Frameworks just for good measure</li>
                  <li>Everything else we failed to mention, just in case.</li>
                  <li>Willingness to spend countless hours reading documentation for said frameworks.</li>
                </ul>
              </div>
            </div>
            <div className={gridItemClasses}>
              <div className="gridTextWrapper">
                <h4>In to This:</h4>
                <p><strong>Web Developer Wanted</strong></p>
                <ul>
                  <li>Be a good Javascript developer</li>
                  <li>Be able to read code and work with basic tools.</li>
                </ul>
              </div>
            </div>
          </div>
          <div><p>Let's look at your end-to-end solutions, figure out what's not needed, and explore simpler, streamlined components in a modular, component-driven architecture.</p></div>
          <div><p>With over a decade of experience in Web Development, I've implemented simple solutions for</p></div>
          <p><ul>
            <li>CI/CD</li>
            <li>APIs (front-end and back-end communication)</li>
            <li>Server-side rendering and static site generation</li>
            <li>Simpler and better-performing alternatives to both of the above</li>
            <li>Non-human Traffic (Search Engine Spiders and readers for the disabled)</li>
          </ul></p>
        </div>
      </div>

      <div style={{...styles.strip, ...styles.rules}}>
        <div style={styles.container} className="container mx-auto">
            <h2 style={styles.heading2}>Rules to Live By</h2>
            <ul>
              <li>Cache what's needed. Optimize and lazy-load the rest.</li>
              <li>SEO is a collaboration between development and marketing</li>
              <li>User-experience should extend to non-human traffic as well as human traffic.</li>
              <li>Servers: Provide a good user-experience for the clients.</li>
              <li>Clients: be good to your server.</li>
            </ul>
        </div>
      </div>
    </main>
  )
}

export default App
