const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const styles = {
  page: {
    color: "#fff",
    paddingBottom:96,
    fontFamily: "Outfit, -apple-system, Roboto, sans-serif, serif",
    background: 'linear-gradient(180deg, rgba(70,164,141,1) 0%, rgba(70,164,141,1) 90%, rgba(55,153,129,1) 100%)'
  },
  strip: {
    width:'100%',
    fontSize: '1.25em',
    lineHeight: '1.2',
    paddingLeft:'1em',
    paddingRight:'1em',
    overflow: 'visible'
  },
  container: {
    paddingTop:40,
    paddingBottom:40,
    overflow:'visible',
    maxWidth:1024,
  },
  headerContainer: {
    maxWidth: '100%',
    marginLeft:'1em'
  },
  id: {
    background: 'rgb(43 35 67)',
    paddingTop:10,
    paddingBottom:30,
    opacity:0
  },
  idGridImgWrapper: {
    background:'none',
    maxWidth:'100px',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding:0,
    height:'100%'
  },
  portfolio: {
    opacity:0,
    background: '#e09042'
  },
  portfolioP: {
    paddingBottom:30
  },
  rules: {
    background: '#e09042',    
  },
  gridBlocks: {
    color:'#222'
  },
  idGridBlocks: {
    padding:0,
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    margin:'auto'
  },
  gridItem: {
    height:'100%'
  },
  heading: {
    marginTop: 0,
    marginBottom: 22,
    maxWidth: 230,
    fontSize:60
  },
  heading2: {
    marginBottom:15,
    fontSize:30
  },
  heading3: {
    fontSize:20
  },
  headingAccent: {
    color: "rgb(169 133 205)",
  },
  paragraph: {
    textAlign: 'center'
  },
  code: {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
  },
  list: {
    marginBottom: 96,
    paddingLeft: 0,
  },
  listItem: {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
  },

  link:linkStyle,

  docLink: {
    ...linkStyle,
    listStyleType: "none",
    marginBottom: 24,
  },

  description: {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
  },

  badge: {
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
  },
}

export default styles